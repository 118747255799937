import ApiService from "@/core/services/api.service";
import Axios from "axios";

const ENDPOINT = "enrollment-process";

export const getEnrollmentProcessOfStudent = (studentUUID) => ApiService.get(`${ENDPOINT}/student/${studentUUID}`);

export const createEnrollmentProcess = (payload) => ApiService.post(`${ENDPOINT}`, payload);

export const generateGroupOfDocuments = (studentCode, payload) => Axios.post(`https://reportsge.ricaldone.edu.sv/public/api/${ENDPOINT}/documents/${studentCode}`, payload, {
    responseType: 'arraybuffer'
});

export const getFamilyMembers = (studentCode, studentUUID) => {
    studentCode.toString().substring(0, 4)

    if (studentCode.toString().substring(0, 4) !== "2024") {
        return Axios.get(`https://apiform.ricaldone.edu.sv/api/student-managers`, {
            headers: {
                'Authorization': `${studentUUID}`,
            }
        });
    }
    return Axios.get("https://admision.ricaldone.edu.sv/app/controllers/php/solicitante_responsable.php?accion=buscar_por_codigo_estudiante&codigo=" + studentCode);
}

export const addStudentFamilyMember = (payload) => Axios.post("https://admision.ricaldone.edu.sv/app/controllers/php/solicitante_responsable.php?accion=crear_por_codigo", payload);

export const getKinships = () => Axios.get("https://admision.ricaldone.edu.sv/app/controllers/php/parentesco.php?accion=todo");

export const getCivilStatuses = () => Axios.get("https://admision.ricaldone.edu.sv/app/controllers/php/estado_civil.php?accion=todo");

export default {
    getEnrollmentProcessOfStudent,
    createEnrollmentProcess,
    generateGroupOfDocuments,
    getFamilyMembers,
    addStudentFamilyMember,
    getKinships,
    getCivilStatuses,
}
